import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { postResendOTP, postResetPassword, postVerifyUser, setToastMessage } from '../../js/redux/actions';
import { clearFormFieldErrors, acceptOnlyNumbersInput } from '../../js/utils';
import useAuth, { AuthContext } from '../../Routes/useAuth';
import BoxInput from '../common/BoxInput';
import Button from '../common/Button';
import ErrorText from '../common/ErrorText';
import Loader from '../common/Loader';
import PhoneNumberInput from '../common/PhoneNumberInput';
import TextInput from '../common/TextInput';
import CountDownTimer from './CountDownTimer';

export default function VerifyOtp({ type }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { setAuthData } = useAuth();
    const scamData = {
        type: location?.state?.type,
        uniqueId: location?.state?.uniqueId
    };

    const initialFormData = {
        countryCode: location?.state?.countryCode,
        phoneNo: location?.state?.phoneNo,
        verifyCode: '',
        password: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [btnDisable, setBtnDisable] = useState(false);
    const [resendBtnDisable, setResendBtnDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showTimer, setShowTimer] = useState(true);

    const handleChange = (key, value) => {
        setErrors(clearFormFieldErrors(key, errors));
        setFormData({
            ...formData,
            [key]: value
        });
    }

    const handleOTPChange = val => {
        setErrors(clearFormFieldErrors('verifyCode', errors));
        if (val) {
            setFormData({
                ...formData,
                verifyCode: val
            });
        }
    }

    const validateFormData = () => {
      let hasError = false;
      const err = Object.assign({}, errors);
      Object.keys(formData).forEach((key) => {
        if (
          typeof formData[key] === 'string' &&
          key !== 'password' &&
          key !== 'phoneNo' &&
          key !== 'countryCode'
        ) {
          if (!formData[key].match(/\w/)) {
            hasError = true;
            err[key] = 'This field is required';
          }
        }
        if (!type) {
          if (!formData['verifyCode']) {
            hasError = true;
            err['verifyCode'] = 'This field is required';
          } else if (formData['verifyCode']) {
            if (formData['verifyCode'].length !== 4) {
              hasError = true;
              err['verifyCode'] = 'This field is required';
            }
          }
        } else {
          if (!formData[key]) {
            hasError = true;
            err[key] = 'This field is required';
          }
        }
      });
      // Password strength check
      if (formData.password) {
        const passwordStrength =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{6,}$/;

        if (!formData.password.match(passwordStrength)) {
          hasError = true;
          err.password =
            'Password must be at least 6 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (e.g., @$!%?&).';
        }
      }
      if (hasError) {
        setErrors(err);
        return false;
      }
      return formData;
    };

    const handleResendOTP = e => {
        e.preventDefault();
        if (!formData.countryCode && !formData.phoneNo) {
            return;
        }
        setShowTimer(true);
        setResendBtnDisable(true);
        setIsLoading(true);
        const postData = {
            countryCode: formData.countryCode,
            phoneNo: formData.phoneNo
        };
        dispatch(postResendOTP(postData)).then(resp => {
            setIsLoading(false);
            setTimeout(() => {
                setResendBtnDisable(false);
            }, 3500);
            if (resp.data.success) {
                handleToastMsg('success', resp?.data?.message);
            }
            else {
                handleToastMsg('error', resp?.data?.message);
            }

        });

    }

    const handleSubmit = e => {
        e.preventDefault();
        if (!formData.countryCode && !formData.phoneNo) {
            return;
        }
        const isValid = validateFormData();
        if (isValid) {
            setBtnDisable(true);
            setIsLoading(true);
            if (type) {
                dispatch(postResetPassword(isValid)).then(resp => {
                    setIsLoading(false);
                    if (resp?.data?.success) {
                        handleToastMsg('success', resp?.data?.message);
                        navigate(`/auth/login`);
                    }
                    else {
                        setTimeout(() => {
                            setBtnDisable(false);
                        }, 3500);
                        handleToastMsg('error', resp?.data?.message);
                    }
                });
            } else {
                setBtnDisable(true);
                dispatch(postVerifyUser(isValid)).then(resp => {
                    setIsLoading(false);
                    if (resp?.data?.success) {
                        handleToastMsg('success', resp?.data?.message);
                        setAuthData(resp?.data?.access_token);
                        if (scamData.uniqueId) {
                            navigate(
                                `/report-scam`,
                                {
                                    state: {
                                        type: scamData.type,
                                        uniqueId: scamData.uniqueId
                                    }
                                });
                        }
                        else {
                            navigate(`/home`);
                        }
                    }
                    else {
                        setTimeout(() => {
                            setBtnDisable(false);
                        }, 3500);
                        handleToastMsg('error', resp?.data?.message);
                    }
                })
            }
        }
    }

    const handleToastMsg = (status, message) => {
        if (!status || !message) return;
        dispatch(
            setToastMessage({
                status,
                message,
            })
        );
    }

    return (
        <div className="fab-verify-otp-container" id="fab-verify-otp-container">
            {isLoading && <Loader parentNode="fab-verify-otp-container" />}
            <div className="fab-ac-top-text">
                {`${type ? 'Reset Password' : 'Verify OTP'}`}
            </div>
            <div className="fab-form-control">
                <div className='fab-form-label'>Enter Phone Number</div>
                <PhoneNumberInput
                    placeholder="01234 56789"
                    value={formData.phoneNo}
                    name="phoneNo"
                    disabled
                />
            </div>
            <div className="fab-form-control">
                <div className='fab-form-label'>Enter OTP</div>
                <BoxInput
                    totalBox={4}
                    onChange={val=>handleOTPChange(val)}
                    secureEntry
                />
                <ErrorText error={errors.verifyCode} errorClass="w3-center" />
            </div>
            {type && <div className="fab-form-control">
                <div className='fab-form-label'>Enter Password</div>
                <TextInput
                    type='password'
                    placeholder='add a unique password'
                    value={formData.password}
                    onChange={(val) => handleChange('password', val)}
                />
                <ErrorText error={errors.password} />
            </div>}
            <div className="fab-verify-top-btn-section">
                <Button
                    type="submit"
                    onClick={e => handleSubmit(e)}
                    buttontext='Verify OTP'
                    disabled={btnDisable}
                    customclass={`primary-btn ${btnDisable ? 'fab-btn-disabled' : ''}`}
                />
                {showTimer &&
                        <CountDownTimer
                            timerContainerStyle={{marginTop:18}}
                            maxCount={60} 
                            setShowTimer={setShowTimer}
                        />
                }
                {!showTimer && 
                            <Button
                                type="submit"
                                onClick={e => handleResendOTP(e)}
                                buttontext='Re-send OTP'
                                disabled={resendBtnDisable}
                                customclass={`fab-btn-link ${resendBtnDisable ? 'fab-btn-disabled' : ''}`}
                                style={{marginTop:15}}
                            />}
            </div>
        </div>
    )
}
