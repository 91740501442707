import React, { useEffect } from 'react';
import '../../App.scss';
import './displayblogposts.scss';
import Loader from '../common/Loader';
import BlogPostCard from '../common/BlogPostCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAllScamArticles } from '../../js/redux/actions';

function DisplayBlogPosts() {
  const dispatch = useDispatch();
  const { data, isFetching } =
    useSelector((state) => state.allScamArticles) || {};

  // Dispatch action to get all scam articles only if data is not available or needs to be updated
  useEffect(() => {
    if ((!data || data.length === 0) && !isFetching) {
      // If no data or if data is outdated (e.g., older than 1 minute), fetch again
      dispatch(getAllScamArticles());
    }
  }, []);

  if (isFetching) {
    return <Loader parentNode="blog-list-container" />;
  }

  return (
    <div className="blog-list-container-wrapper">
      <div className="flex-container w3-padding blue-color w3-hide-large w3-hide-medium">
        <h5 className="w3-padding-small">Daily Blogs</h5>
      </div>
      <div className="blog-list-container">
        {data && data.length > 0 ? (
          data
            .slice()
            .sort((a, b) => (b.trendingScore ?? 0) - (a.trendingScore ?? 0))
            .map((item, i) => (
              <div className="blog-list-item">
                <BlogPostCard index={i} key={item.slug} item={item} />
              </div>
            ))
        ) : (
          <div className="fab-no-data w3-center">No data available</div>
        )}
      </div>
    </div>
  );
}

export default DisplayBlogPosts;
