import { useRef, useEffect } from 'react';

const useControlledFetch = ({ fetchFn, shouldFetch, interval = 60000 }) => {
  const lastFetched = useRef(0); // Tracks the last fetch time
  const fetchInProgress = useRef(false); // Tracks ongoing fetch status

  useEffect(() => {
    const attemptFetch = async () => {
      if (!fetchInProgress.current && shouldFetch) {
        const now = Date.now();

        if (now - lastFetched.current > interval) {
          fetchInProgress.current = true;
          try {
            await fetchFn(); // Execute the fetch function
            lastFetched.current = now; // Update the last fetched timestamp
          } catch (error) {
            console.error('Fetch failed:', error);
          } finally {
            fetchInProgress.current = false; // Reset fetch flag
          }
        }
      }
    };

    attemptFetch();
  }, [fetchFn, shouldFetch, interval]);
};

export default useControlledFetch;
