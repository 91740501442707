import React, { useState, useEffect, useRef } from 'react';
import DownCaretIcon from '../../../images/icons/dropdown_down.svg';
import UpCaretIcon from '../../../images/icons/dropdown_up_active.svg';
import './drop-down.scss';

export default function Dropdown(props) {
  const {
    label,
    placeholder,
    options,
    customCls,
    labelCls,
    onChange,
    selectedValue,
    containerCls,
    optionCls,
    valueCls,
    disabled,
    height,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown container

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  const onClickOption = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const renderValues = () => {
    if (!selectedValue) {
      return <div className="fab-dropdown-placeholder">{placeholder}</div>;
    }
    return (
      <div className={`fab-dropdown-selected-value ${valueCls || ''}`}>
        {selectedValue.value}
      </div>
    );
  };

  const renderOption = (option) => {
    let className = 'fab-dropdown-option';
    if (option.value === selectedValue?.value) className += ' selected';
    return (
      <div
        key={option.value}
        className={className}
        onClick={() => onClickOption(option)}
      >
        {option.value}
      </div>
    );
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if click is outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {label && (
        <div className={`fab-form-label ${labelCls || ''}`}>{label}</div>
      )}
      <div
        ref={dropdownRef}
        className={`fab-dropdown-container ${
          isOpen && 'fab-dropdown-focused'
        } ${customCls || ''}`}
        style={{ height }}
      >
        <div
          className={`fab-dropdown-selection ${
            disabled && 'fab-dropdown-disabled'
          }`}
          onClick={() => !disabled && onClick()}
        >
          {renderValues()}
          <img
            className="fab-dropdown-icon"
            src={isOpen ? UpCaretIcon : DownCaretIcon}
            alt="caret-icon"
          />
        </div>
        {isOpen && (
          <div
            className={`${
              containerCls ? containerCls : 'fab-dropdown-options-container'
            }`}
          >
            {options.map(renderOption)}
          </div>
        )}
      </div>
    </>
  );
}
