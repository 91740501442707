import React, { useLayoutEffect } from 'react';
import { Route, Routes as DefaultRoutes, useLocation } from 'react-router-dom';
import AppLayout from '../components/common/AppLayout';
import SplitLayout from '../components/common/SplitLayout';
import FullScreenLayout from '../components/common/FullScreenLayout';
import AuthLayout from '../components/Auth/AuthLayout';
import ReportList from '../components/Report/ReportList';
import ReportDetail from '../components/Report/ReportDetail';
import Report from '../components/Report/Report';
import PrivacyPolicy from '../components/NavLinks/PrivacyPolicy';
import TermsService from '../components/NavLinks/TermsService';
import AboutUs from '../components/NavLinks/AboutUs';
import FAQSection from '../components/FAQSection';
import PageNotFound from './PageNotFound';
import PrivateOutlet from './PrivateOutlet';
import LeaderBoard from '../components/common/LeaderBoard';
import DisplayBlogPosts from '../components/Blog/DisplayBlogPosts';

export default function Routes() {
  const location = useLocation();

  // Scroll to top when location changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <DefaultRoutes>
      {/* Full-Screen Routes (outside of AppLayout) */}
      <Route
        path="privacy-policy"
        element={
          <FullScreenLayout>
            <PrivacyPolicy />
          </FullScreenLayout>
        }
      />
      <Route
        path="terms-of-use"
        element={
          <FullScreenLayout>
            <TermsService />
          </FullScreenLayout>
        }
      />
      <Route
        path="about"
        element={
          <FullScreenLayout>
            <AboutUs />
          </FullScreenLayout>
        }
      />
      <Route
        path="faq"
        element={
          <FullScreenLayout>
            <FAQSection />
          </FullScreenLayout>
        }
      />

      {/* Fallback- */}
      <Route path="*" element={<PageNotFound />} />

      {/* AppLayout as the parent */}
      <Route path="/" element={<AppLayout />}>
        {/* Public Routes */}
        <Route
          path="/"
          element={<SplitLayout activeTab="Learn" showType="home" />}
        />
        <Route
          path="/home"
          element={<SplitLayout activeTab="Learn" showType="home" />}
        />
        <Route
          path="/learn"
          element={
            <SplitLayout activeTab="Learn" showType="tabs">
              <DisplayBlogPosts />
            </SplitLayout>
          }
        />
        <Route
          path="/leader-board"
          element={
            <SplitLayout activeTab="LeaderBoard" showType="tabs">
              <LeaderBoard />
            </SplitLayout>
          }
        />

        {/* Auth Routes */}
        <Route
          path="auth"
          element={
            <SplitLayout showType="modal">
              <AuthLayout />
            </SplitLayout>
          }
        >
          <Route path="register" element={<AuthLayout />} />
          <Route path="login" element={<AuthLayout />} />
          <Route path="forgot-password" element={<AuthLayout />} />
          <Route path="reset-password" element={<AuthLayout />} />
          <Route path="verify-otp" element={<AuthLayout />} />
        </Route>

        {/* Report Routes */}
        <Route
          path="reports"
          element={
            <SplitLayout activeTab="Reports" showType="tabs">
              <ReportList listType="main-list" />
            </SplitLayout>
          }
        />
        <Route
          path="reports/detail/:id"
          element={
            <SplitLayout activeTab="Reports" showType="tabs">
              <ReportDetail />
            </SplitLayout>
          }
        />

        {/* Private Routes */}
        <Route element={<PrivateOutlet />}>
          <Route
            path="report-scam"
            element={
              <SplitLayout activeTab="ReportScam" showType="tabs">
                <Report />
              </SplitLayout>
            }
          />
          {/* <Route
            path="reports/my-report"
            element={
              <SplitLayout activeTab="Reports" showType="tabs">
                <ReportList listType="my-list" />
              </SplitLayout>
            }
          /> */}
        </Route>
      </Route>
    </DefaultRoutes>
  );
}
