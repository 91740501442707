import React from 'react';
import Portal from '../Portal';
import './loader.scss';

export default function Loader({ parentNode }) {
  return (
    <Portal parent={parentNode}>
      <div className="fab-css-loader-container">
        <div className="fab-css-loader-wrapper">
          <div className="fab-css-loader-bg">
            <div className="fab-css-loader"></div>
          </div>
        </div>
      </div>
    </Portal>
  );
}
