import React, { useState, useCallback } from 'react';
import './App.scss';
import Routes from './Routes';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import ToastMessage from './components/common/ToastMessage';

function App() {
  const [showDrawer, setShowDrawer] = useState(false);
  const setOrToggleDrawer = useCallback((value) => {
    setShowDrawer((prev) => (typeof value === 'boolean' ? value : !prev));
  }, []);

  return (
    <div className="fabcaller-app">
      <ToastMessage />
      <Navbar setOrToggleDrawer={setOrToggleDrawer} showDrawer={showDrawer} />
      <Routes section="auth" />
      <Footer />
    </div>
  );
}

export default App;
