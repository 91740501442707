import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSpammerDetail,
  postScamRejection,
  scamVerification,
  setToastMessage,
} from '../../js/redux/actions';
import './scam.scss';
import { clearFormFieldErrors, getProofUrl, maskString } from '../../js/utils';
import downloadBtn from '../../images/icons/download_btn.svg';
import ArrowLeftBlueBtn from '../../images/icons/arrow_left_blue.svg';
import moment from 'moment';
import { axiosApiCall } from '../../js/redux/actions';
import ProgressBar from '../common/ProgressBar';
import Loader from '../common/Loader';
import Button from '../common/Button';
import Modal from '../common/Modal';
import TextInput from '../common/TextInput';
import { SELECT_TYPE_OPTIONS } from '../../js/constants';
import PhoneNumberInput from '../common/PhoneNumberInput';
import useAuth from '../../Routes/useAuth';
import ErrorText from '../common/ErrorText';
import BlogPostCard from '../common/BlogPostCard';

const ReportDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const spammerList = useSelector(
    (state) => state?.spammerListByType?.data?.data,
  );
  const { id } = useParams();
  const { userData } = useAuth();
  const [reportData, setReportData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [reportType, setReportType] = useState({});
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const initialmodalConfig = {
    class: 'w3-hide',
    show: false,
    type: null,
  };
  const [triggerModal, setTriggerModal] = useState(initialmodalConfig);
  const [btnDisable, setBtnDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Memoizing spammer count and next link
  const count = spammerList?.length;
  const nextLink = useMemo(() => {
    return spammerList?.reduce((agg, item, idx) => {
      if (agg) return agg;
      if (item.id === id && idx < spammerList.length - 1) {
        return { idx: idx + 1, id: spammerList[idx + 1].id };
      }
      return agg;
    }, null);
  }, [id, spammerList]);

  // Memoizing file types
  const filterFiles = (type) =>
    reportData?.reports?.filter((item) =>
      item.proof.match(new RegExp(`\.(?:${type})$`, 'i')),
    ) || [];

  const videos = useMemo(() => filterFiles('mp4|mkv'), [reportData]);
  const audios = useMemo(() => filterFiles('mp3|ma4'), [reportData]);
  const images = useMemo(() => filterFiles('png|jpg|jpeg'), [reportData]);
  const pdf = useMemo(() => filterFiles('pdf'), [reportData]);

  useEffect(() => {
    dispatch(getSpammerDetail(id)).then((resp) => {
      const respData = resp?.data;
      if (respData?.success && respData?.data) {
        const data = respData.data;
        setReportData(data);
        setReportType(
          SELECT_TYPE_OPTIONS.find((item) => item.key === data.type),
        );
      }
    });
  }, [id]);

  const handleDownload = async (url) => {
    const fileName = url.split('/').pop();
    let progress = 0;
    setIsDownloading(true);
    try {
      const res = await axiosApiCall({
        url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
          );
          setPercentage(progress);
        },
      });
      const { data } = res;
      const fileUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setIsDownloading(false);
      setPercentage(0);
    }
  };

  const renderDownloadList = (fileType, filesArray) => (
    <>
      <div className="fab-report-detail-proof-type">{fileType}</div>
      {filesArray.map((item) => (
        <div className="fab-report-detail-proof-download-section" key={item.id}>
          <div className="fab-report-detail-file-name">
            {item.proof.split('/').pop()}
          </div>
          <img
            src={downloadBtn}
            alt="download-btn"
            className="fab-cursor-pointer"
            onClick={() => handleDownload(getProofUrl(item.proof))}
          />
        </div>
      ))}
    </>
  );

  const handleChange = (key, value) => {
    setErrors(clearFormFieldErrors(key, errors));
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleVerificationAction = (actionType, apiCall, successMessage) => {
    const requiredField = actionType === 'VERIFY' ? 'name' : 'reason';
    if (!formData[requiredField] || /^\s*$/.test(formData[requiredField])) {
      setErrors({
        ...errors,
        [requiredField]: 'This field is required',
      });
      return;
    }

    setBtnDisable(true);
    setIsLoading(true);
    dispatch(apiCall(formData)).then((resp) => {
      setIsLoading(false);
      if (resp.data.success) {
        dispatch(
          setToastMessage({ status: 'success', message: resp?.data?.message }),
        );
        setTriggerModal(initialmodalConfig);
        document.location.reload();
      } else {
        setTimeout(() => setBtnDisable(false), 3500);
        dispatch(
          setToastMessage({
            status: 'error',
            message: 'Something went wrong!',
          }),
        );
      }
    });
  };

  const handleVerify = () =>
    handleVerificationAction(
      'VERIFY',
      scamVerification,
      'Verification successful',
    );
  const handleReject = () =>
    handleVerificationAction(
      'REJECT',
      postScamRejection,
      'Rejection successful',
    );

  const triggerRejectOrVerifyModal = (actionType) => {
    const fData = {
      ...formData,
      scammerId: reportData.scammerId,
      uniqueId: reportData.uniqueId,
      id: reportData.id,
      [actionType === 'VERIFY' ? 'name' : 'reason']: '',
    };
    setFormData(fData);
    setTriggerModal({
      class: 'w3-show',
      type: actionType,
      show: true,
    });
  };

  const handleClose = () => {
    setTriggerModal(initialmodalConfig);
    setFormData({});
    setErrors({});
  };

  const getModalContent = (viewType) => {
    const titleMap = {
      number: 'Phone number of Scammer',
      upi: 'UPI id of Scammer',
      sms: 'SMS/Email scam link',
      website: 'Web URL of scammer',
    };
    const title = titleMap[reportType] || '';
    const btnText = viewType === 'VERIFY' ? 'Verify' : 'Reject';

    return (
      <div className="fab-scam-verify-container">
        <div className="fab-form-control">
          <div className="fab-form-label">{title}</div>
          {reportType === 'number' ? (
            <PhoneNumberInput
              placeholder="01234 56789"
              value={formData?.scammerId || ''}
              name="scammerId"
              disabled
            />
          ) : (
            <TextInput
              type="text"
              value={formData?.scammerId || ''}
              name="scammerId"
              disabled
            />
          )}
        </div>
        {viewType === 'VERIFY' && (
          <div className="fab-form-control" style={{ marginBottom: 20 }}>
            <div className="fab-form-label">Scammer Name</div>
            <TextInput
              type="text"
              placeholder="Scammer Name"
              value={formData?.name || ''}
              onChange={(val) => handleChange('name', val)}
              name="name"
            />
            <ErrorText error={errors.name} errorClass="w3-center" />
          </div>
        )}
        {viewType === 'REJECT' && (
          <div className="fab-form-control">
            <div className="fab-form-label">Reason for rejection</div>
            <div className="fab-textarea-container fab-input-container">
              <textarea
                className={`fab-input ${
                  !formData?.reason
                    ? 'fab-input-placeholder'
                    : 'fab-input-active'
                }`}
                name="reason"
                placeholder="Reason for rejection"
                value={formData?.reason || ''}
                onChange={(e) => handleChange('reason', e.target.value)}
              />
            </div>
            <ErrorText error={errors.reason} errorClass="w3-center" />
          </div>
        )}
        <div>
          <Button
            onClick={viewType === 'VERIFY' ? handleVerify : handleReject}
            buttontext={btnText}
            disabled={btnDisable}
            customclass={`primary-btn ${btnDisable ? 'fab-btn-disabled' : ''}`}
            style={{ width: '100%' }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {isDownloading && <ProgressBar percent={percentage} />}
      {(isDownloading || isLoading) && (
        <Loader parentNode="fab-report-detail-container" />
      )}
      <div
        className="fab-report-detail-container"
        id="fab-report-detail-container"
      >
        <div className="fab-report-detail-top-section">
          <div className="fab-cursor-pointer" onClick={() => navigate(-1)}>
            <img
              src={ArrowLeftBlueBtn}
              alt="back-btn"
              style={{ marginRight: 12 }}
            />
          </div>
          <div className="fab-page-heading">
            Report Detail
            {nextLink && (
              <Link
                className="w3-right w3-small"
                to={`/reports/detail/${nextLink.id}`}
              >
                Next {nextLink.idx} of {count}
              </Link>
            )}
          </div>
        </div>
        <div className="fab-report-detail-card">
          <div className="fab-report-detail-card-inner">
            <div className="w3-block">
              <div className="fab-report-detail-card-title">
                {reportData?.scammerId}

                <div
                  className={`fab-report-detail-card-status ${
                    reportData?.status === 'ACTIVE' ? 'active' : ''
                  }`}
                >
                  {reportData?.status}
                </div>
              </div>
              {reportData?.json?.scammerName && (
                <div className="fab-report-detail-card-sub-desc display-flex">
                  <span className="fab-report-detail-dc-sub-title custom-min-width-106">
                    Name
                  </span>
                  {reportData?.json?.scammerName}
                </div>
              )}
              <div className="fab-report-detail-card-sub-desc display-flex">
                <span className="fab-report-detail-dc-sub-title custom-min-width-106">
                  Last reported at
                </span>
                {reportData?.lastReportedAt &&
                  moment(reportData?.lastReportedAt).format('lll')}
              </div>
              <div className="fab-report-detail-card-sub-desc display-flex">
                <span className="fab-report-detail-dc-sub-title custom-min-width-106">
                  Report count
                </span>
                {reportData?.reports?.length}
              </div>
              {reportData?.reports?.length > 0 && (
                <div className="fab-report-detail-card-sub-desc display-flex">
                  <span className="fab-report-detail-dc-sub-title custom-min-width-106">
                    Reported By
                  </span>
                  {reportData?.reports[0].reporter?.name}
                </div>
              )}
              {reportData?.status === 'REJECTED' &&
                reportData?.reports?.length > 0 && (
                  <div className="fab-report-detail-card-sub-desc display-flex">
                    <span className="fab-report-detail-dc-sub-title custom-min-width-106">
                      Verified By
                    </span>
                    {reportData?.reports[0].verifier?.name}
                  </div>
                )}
            </div>
          </div>
          {userData?.rank > 10 && reportData?.status === 'PENDING' && (
            <div className="flex-container align-items-center justify-content-space-between w3-margin-top">
              <Button
                onClick={() => triggerRejectOrVerifyModal('REJECT')}
                buttontext="Reject"
                customclass="secondary-btn fab-report-list-card-verify-btn w3-red"
              />
              <Button
                onClick={() => triggerRejectOrVerifyModal('VERIFY')}
                buttontext="Verify"
                customclass="secondary-btn fab-report-list-card-verify-btn"
              />
            </div>
          )}
        </div>

        {reportData?.status === 'REJECTED' &&
          reportData?.json?.rejectReason && (
            <div className="fab-report-detail-card">
              <div className="fab-report-detail-description-container">
                <div className="fab-report-detail-dc-title">
                  Reason for reject
                </div>
                <div className="fab-report-detail-dc-text w3-margin-bottom">
                  {reportData?.json?.rejectReason}
                </div>
              </div>
            </div>
          )}

        {reportData?.reports?.length > 0 &&
          reportData?.reports?.map((rItem) => (
            <div className="fab-report-detail-card">
              <div className="fab-report-detail-description-container">
                <div key={rItem.id} className="flex flex-col">
                  <div className="flex-container align-items-center fab-report-detail-dc-reported-by">
                    <span className="fab-report-detail-dc-sub-title custom-min-width-106">
                      Reported By
                    </span>
                    <div>{rItem?.reporter?.name}</div>
                  </div>
                  <div className="fab-report-detail-dc-text flex-container">
                    <span className="fab-report-detail-dc-sub-title custom-min-width-106">
                      Details
                    </span>
                    <div>
                      {rItem.detail
                        ?.replace(/\n+/g, '\n')
                        .split('\n')
                        .map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                  {rItem?.scamArticle && (
                    <div className="flex-container fab-report-detail-dc-reported-by">
                      <span className="fab-report-detail-dc-sub-title custom-min-width-90">
                        Tagged Article
                      </span>
                      <div style={{ width: '100%' }}>
                        <BlogPostCard
                          key={rItem?.scamArticle?.slug}
                          item={rItem?.scamArticle}
                          small
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

        {(images.length > 0 ||
          videos.length > 0 ||
          audios.length > 0 ||
          pdf.length > 0) && (
          <div className="fab-report-detail-card">
            <div className="fab-report-detail-description-container">
              <div className="fab-report-detail-dc-title">Proof</div>
              {images.length > 0 && (
                <div className="fab-report-detail-proof-img-container">
                  <div className="fab-report-detail-proof-type">Images</div>
                  {/* Images */}
                  {images.length > 0 && (
                    <div>
                      {images.map((item, index) => (
                        <div key={index} className="w3-col s4 w3-margin-bottom">
                          <div className="w3-border w3-round w3-white w3-center">
                            <img
                              src={getProofUrl(item.proof)}
                              alt="Proof"
                              className="w3-round w3-margin-bottom"
                              style={{
                                height: '120px',
                                objectFit: 'cover',
                              }}
                            />
                            <div className="w3-small w3-text-gray">
                              {item.proof.split('/').pop()}
                            </div>
                            <img
                              src={downloadBtn}
                              alt="download-btn"
                              className="fab-cursor-pointer"
                              onClick={() =>
                                handleDownload(getProofUrl(item.proof))
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {videos.length > 0 && renderDownloadList('Videos', videos)}
              {audios.length > 0 && renderDownloadList('Audios', audios)}
              {pdf.length > 0 && renderDownloadList('Pdf', pdf)}
            </div>
          </div>
        )}

        {triggerModal.show && (
          <Modal
            openClass={triggerModal.class}
            hideOverLay={true}
            showCloseBtn={true}
            handleClose={handleClose}
          >
            <div className="fab-page-heading fab-page-heading-custom-pad">
              {triggerModal.type === 'REJECT' ? 'Reject ' : 'Verify '}Report
            </div>
            {getModalContent(triggerModal.type)}
          </Modal>
        )}
      </div>
    </>
  );
};

export default ReportDetail;
